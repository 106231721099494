import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

export const CustomTextField = withStyles({
  root: {
    "& label": {
      color: "#babac0",
    },
    "& label.Mui-focused": {
      color: "#fb8c00",
    },
    "& .MuiInputBase-input": {
      color: "#ffffff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#babac0",
      },
      "&:hover fieldset": {
        borderColor: "#fb8c00",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fb8c00",
      },
    },
  },
})(TextField);

export const CustomButton = withStyles({
  root: {
    backgroundColor: "#42a5f5",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#2b95ea",
    },
    "&.Mui-disabled": {
      color: "#706969",
      backgroundColor: "#c6c6c6",
    },
  },
})(Button);

export const ErrorButton = withStyles({
  root: {
    backgroundColor: "#f62f2f",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#ea1919",
    },
    "&.Mui-disabled": {
      color: "#706969",
      backgroundColor: "#c6c6c6",
    },
  },
})(Button);

export const WhiteCheckbox = withStyles({
  root: {
    color: "#ffffff",
    "&.Mui-checked": {
      color: "#ffffff",
    },
  },
})(Checkbox);
