import { yupResolver } from "@hookform/resolvers";
import React from "react";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import "./LoginLayout.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { setInfoPopup } from "../../redux/actions/infoPopupActions";
import { login } from "../../redux/actions/profileActions";
import { CustomButton, CustomTextField } from "../../components/@CustomComponents";
import FullPageLoader from "../../components/FullPageLoader/FullPageLoader";
import useShowPassword from "./useShowPassword";
import { ROUTES } from "../../constants";

const LoginLayout = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const [adornment, type] = useShowPassword();

  const loading = useSelector(({ profile }) => profile.loading);

  const loginSchema = yup.object().shape({
    username: yup.string().required("Обязательно поле"),
    password: yup.string().required("Обязательное поле"),
  });

  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(loginSchema),
  });

  const loginForm = ({ username, password }) => {
    dispatch(
      login(
        username,
        password,
        () => push(ROUTES.orders),
        () => dispatch(setInfoPopup("Неверный логин и/или пароль")),
      ),
    );
  };

  return (
    <div className="loginLayout darkTheme">
      <Link to={ROUTES.createOrder} style={{ display: "none" }} />
      {loading && <FullPageLoader withOverlay />}
      <div className="loginLayout__content">
        <div className="loginLayout__formBox">
          <div className="loginLayout__icon">
            <LockOutlinedIcon />
          </div>
          <h2 className="loginLayout__enter">Вхід</h2>
          <form className="loginLayout__form" noValidate onSubmit={handleSubmit(loginForm)}>
            <CustomTextField
              className={"loginLayout__input"}
              name={"username"}
              label={"Логин"}
              inputRef={register}
              variant={"outlined"}
              error={!!errors.username}
              helperText={errors.username?.message}
            />
            <CustomTextField
              className={"loginLayout__input"}
              name={"password"}
              type={type}
              autoComplete={"current-password"}
              label={"Пароль"}
              inputRef={register}
              variant={"outlined"}
              error={!!errors.password}
              helperText={errors.password?.message}
              InputProps={{
                endAdornment: adornment,
              }}
            />
            <CustomButton type={"submit"} color={"primary"} fullWidth variant={"contained"}>
            Увійти
            </CustomButton>
          </form>
        </div>
        <div className="loginLayout__bg">
          <img src={require("../../assets/img/login_transpadent.png")} alt="" className="loginLayout__logo" />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
