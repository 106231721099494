import React, { useState } from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useShowPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const type = passwordVisible ? "text" : "password";

  function handleClickShowPassword() {
    setPasswordVisible((prevState) => !prevState);
  }
  function handleMouseDownPassword(e) {
    e.preventDefault();
  }

  const adornment = (
    <InputAdornment position="end">
      <IconButton
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
        type="button"
        style={{ minWidth: 56 }}
      >
        {passwordVisible ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );

  return [adornment, type];
};

export default useShowPassword;
